/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, shape,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import customBreakpoints from '../../../../../helpers/customBreakpoints';
import { determineCollectionPrefixPath } from '../../../../../helpers/determineInternationalizationPath';
import useImageUrlQualityTransformation from '../../../../../helpers/hooks/useImageUrlQualityTransformation';
import { actions as tagManagerActions } from '../../../../../../state/ducks/TagManager/ducks/TagManager';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(3, 0, 0, 0),
        height: '337px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    plantsRoot: {
        padding: theme.spacing(3, 0, 0, 0),
        height: '482px',
        backgroundSize: 'cover',
    },
    plantsMobileRoot: {
        aspectRatio: '16/15',
        lineHeight: '0.5',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    mobileRoot: {
        height: '210px',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    plantsMobileImg: {
        width: '100%',
        minHeight: '100%',
        maxHeight: '210px',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 'unset',
        },
    },
    mobileImg: {
        width: '100%',
        minHeight: '100%',
        // maxHeight: '210px',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 'unset',
        },
    },
    headline: {
        margin: `0 auto ${theme.spacing(3)}px auto`,
        maxWidth: '500px',
        textAlign: 'center',
        fontSize: '34px',
        color: theme.palette.common.black, // TODO: Font color should come from CMS
        '& p,h1,h2,h3,h4,h5,h6': {
            marginBottom: 0, // fix for spacing for bumping Gift Finder
            fontSize: '34px',
            '& strong': {
                display: 'block',
            },
        },
    },
    plantsMobileHeadline: {
        margin: '0 auto',
        width: '100%',
        textAlign: 'center',
        color: theme.palette.common.black,
        '& p,h1,h2,h3,h4,h5,h6': {
            fontSize: '16px',
            fontWeight: 'normal',
            '& strong': {
                display: 'block',
            },
        },
    },
    mobileHeadline: {
        margin: '0 auto',
        width: '100%',
        textAlign: 'center',
        color: theme.palette.common.black,
        '& p,h1,h2,h3,h4,h5,h6': {
            fontSize: '16px',
            fontWeight: 'normal',
            '& strong': {
                display: 'block',
            },
        },
        '& > *': {
            margin: '0 auto 3px auto',
        },
    },
    ctaLink: {
        textDecoration: 'none',
        position: 'relative',
        float: 'left',
        width: '100%',
        height: '337px',
        marginBottom: '10px',
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            height: 'auto',
            marginBottom: '0px',
        },
        [theme.breakpoints.down('sm')]: {
            float: 'unset',
        },
    },
    plantsCTALink: {
        margin: '0 auto',
        width: '100%',
        maxWidth: '1270px',
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            height: 'auto',
            marginBottom: '0px',
        },
        [theme.breakpoints.down('sm')]: {
            float: 'unset',
        },
    },
    ctaLinkBtn: {
        textDecoration: 'none',
    },
    ctaMobileLinkBtn: {
        textDecoration: 'none',
        position: 'absolute',
        bottom: '5px',
        right: '11%',
        '@media screen and (max-width: 360px)': {
            right: '9.5%',
        },
        '@media screen and (max-width: 375px)': {
            right: '10.5%',
        },
    },
    frenchBtn: {
        right: '1% !important',
        '& span': {
            '@media screen and (max-width: 375px)': {
                fontSize: '13px',
            },
        },
    },
    ctaBannerLinkBtn: {
        textDecoration: 'none',
    },
    cta: {
        padding: '10px 20px',
        textTransform: 'uppercase',
        boxSizing: 'border-box',
        display: 'block',
        margin: '14% auto',
        fontSize: '16px',
        fontWeight: '500',
        borderRadius: '0',
        boxShadow: 'unset',
        backgroundColor: theme?.palette?.white,
        color: theme?.palette?.colorPrimary,
        '&:hover, &:focus': {
            backgroundColor: theme?.palette?.colorPrimary,
            color: theme?.palette?.white,
        },
        [theme.breakpoints.down(1550)]: {
            margin: '16% auto',
        },
        [theme.breakpoints.down(1450)]: {
            margin: '20% auto',
        },
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            margin: '30% auto',
        },
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            margin: '30% auto',
        },
    },
    ctaOverride: {
        top: '68%',
        position: 'absolute',
        left: '50%',
        margin: 0,
        transform: 'translateX(-50%)',
    },
    mobileCta: {
        display: 'block',
        margin: '0 auto',
        backgroundColor: theme?.palette?.white,
        color: theme?.palette?.colorPrimary,
        borderRadius: '0',
        boxShadow: 'unset',
        '&:hover, &:focus': {
            backgroundColor: theme?.palette?.colorPrimary,
            color: theme?.palette?.white,
        },
    },
});

const HeroImage = ({
    brand,
    classes,
    language,
    data: {
        hero_link: {
            href = '',
            title = '',
        } = {},
        hero_image = {},
        mobile_hero_image = {},
        markdown = '',
        hero_text_font_color = '',
        hero_text_font_type = '',
    } = {},
    ssrDeviceType = '',
}) => {
    let heroImageDesktop = '';
    let heroImageMobile = '';
    const dispatch = useDispatch();
    const handleEventClick = () => dispatch(tagManagerActions.trackEvent({
        eventName: 'feature_interaction', // Requird for GA4 feature_click
        eventCategory: 'Homepage',
        eventAction: 'Click',
        eventLabel: `Hero Banner - ${title}`,
    }));
    heroImageDesktop = useImageUrlQualityTransformation(`${hero_image?.url}?&auto=webp&optimize={medium}`);
    heroImageMobile = useImageUrlQualityTransformation(`${mobile_hero_image?.url}?&auto=webp&optimize={medium}`);
    const isMobile = ssrDeviceType === 'mobile';
    const heroPreloadImage = isMobile ? heroImageMobile : heroImageDesktop;
    return (
        <div
            className={brand?.code === 'PLA' ? classes.plantsCTALink : classes.ctaLink}
        >
            { brand?.code === 'PLA' && <Helmet><link rel="preload" href={heroPreloadImage} as="image" /></Helmet> }
            <Media
                query="(min-width: 767px)"
                defaultMatches={ssrDeviceType === 'desktop'}
                render={() => (
                    (!title && !title.length)
                        ? (
                            <div
                                style={{
                                    backgroundImage: `url(${heroImageDesktop})`,
                                }}
                                className={brand?.code === 'PLA' ? classes.plantsRoot : classes.root}
                                onClick={handleEventClick}
                                onKeyDown={handleEventClick}
                                role="presentation"
                            />
                        ) : (
                            <Link
                                to={`${brand?.code === 'CAN' ? determineCollectionPrefixPath(href, false) : determineCollectionPrefixPath(href, true)}`}
                                className={classes.ctaLinkBtn}
                                onClick={handleEventClick}
                                aria-label={title}
                                aria-labelledby={title}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${heroImageDesktop})`,
                                    }}
                                    className={brand?.code === 'PLA' ? classes.plantsRoot : classes.root}
                                >
                                    <div
                                        className={classes.headline}
                                        style={{
                                            fontFamily: hero_text_font_type || null,
                                            color: hero_text_font_color || null,
                                        }}
                                    >
                                        <ReactMarkdown
                                            source={markdown}
                                            skipHtml
                                        />
                                    </div>
                                    {title && brand?.code !== 'PLA' ? (
                                        <div
                                            className={`${classes.cta} ${brand?.code === 'CAN' ? classes.ctaOverride : ''}`}
                                            data-test="hp-shop-now-button"
                                            id="btn-shop-now"
                                            name="shopNowButton"
                                        >
                                            {title}
                                        </div>
                                    ) : null}
                                </div>
                            </Link>
                        )
                )}
            />

            <Media
                query="(max-width: 767px)"
                defaultMatches={ssrDeviceType === 'mobile'}
                render={() => (
                    <div className={brand?.code === 'PLA' ? classes.plantsMobileRoot : classes.mobileRoot}>
                        <Link
                            to={`${brand?.code === 'CAN' ? determineCollectionPrefixPath(href, false) : determineCollectionPrefixPath(href, true)}`}
                            className={classes.ctaBannerLinkBtn}
                            onClick={handleEventClick}
                            aria-label={title}
                            aria-labelledby={title}
                        >
                            <img className={classes.mobileImg} src={heroImageMobile} alt={title} loading="eager" />
                            <div
                                className={classes.mobileHeadline}
                                style={{
                                    fontFamily: hero_text_font_type || null,
                                }}
                            >
                                <ReactMarkdown
                                    source={markdown}
                                    skipHtml
                                />
                            </div>
                            {title && brand?.code !== 'PLA' && (
                                <div className={`${classes.ctaMobileLinkBtn} ${language === '/fr' && classes.frenchBtn}`}>
                                    <Button
                                        variant="contained"
                                        color="white"
                                        className={classes.mobileCta}
                                        size="small"
                                        disableRipple
                                        data-test="hp-shop-now-button"
                                        id="btn-shop-now"
                                        name="shopNowButton"
                                    >
                                        {title}
                                    </Button>
                                </div>
                            )}
                        </Link>
                    </div>
                )}
            />
        </div>
    );
};

HeroImage.defaultProps = {
    ssrDeviceType: '',
};

HeroImage.propTypes = {
    brand: shape({
        code: string,
    }).isRequired,
    data: shape({
        hero_link: {
            title: string,
            href: string,
        },
        hero_image: object,
        mobile_hero_image: object,
        markdown: string,
        hero_text_font_color: string,
        hero_text_font_type: string,
    }).isRequired,
    classes: object.isRequired,
    ssrDeviceType: string,
    language: string.isRequired,
};

export default withStyles(styles)(HeroImage);
